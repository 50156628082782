


body {
    overflow: hidden !important;
}

.container-fluid {
    width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: auto;
    margin-left: auto;
}

.dropup,
.dropright,
.layout-dropdown,
.dropleft {
    position: relative;
}

.layout-dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.layout-dropdown-toggle:empty::after {
    margin-left: 0;
}

.layout-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.layout-dropdown-menu-right {
    left: auto;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    font-size: 14px;
}

    .layout-dropdown-menu-right span {
        margin: 10px;
        color: black;
    }

    .layout-dropdown-menu-right i {
        color: #555464 !important;
        text-shadow: #ffffff 1px 1px 1px;
    }

.app-header-menu {
    height: 48px;
}

    .app-header-menu .app-header-menu-item {
        padding: 15px;
        cursor: pointer;
        color: black;
    }

        .app-header-menu .app-header-menu-item i {
            color: #555464 !important;
            text-shadow: #ffffff 1px 1px 1px;
        }

        .app-header-menu .app-header-menu-item:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        .app-header-menu .app-header-menu-item i {
            color: #555464 !important;
            text-shadow: #ffffff 1px 1px 1px;
        }

    .app-header-menu .dropdown-toggle {
        font-size: 15px;
    }

.dropdown-toggle::after {
    border: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #555464 !important;
    text-shadow: #fff 1px 1px 1px;
}

    .nav-link span {
        color: #000;
        text-shadow: none;
    }

    .nav-link:hover, .nav-link:focus {
        text-decoration: none;
    }

    .nav-link.disabled {
        color: #6c757d;
        pointer-events: none;
        cursor: default;
    }


#sidebarToggle {
    float: right;
    color: teal !important;
    text-align: right;
    width: 100%;
    border: dashed 1px grey;
}

.navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    height: 48px;
    margin: 0px !important;
}

    .navbar > .container,
    .navbar > .container-fluid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 2.25rem;
    line-height: inherit;
    white-space: nowrap;
}

    .navbar-brand.active {
        background-color: #fff;
    }

    .navbar-brand img {
        max-width: 100px;
        max-height: 40px;
        margin-top: 4px;
    }

.navbar .company-title {
    font-size: 20px !important;
    font-weight: 500;
}

.navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none;
}

.ml-auto {
    float: right;
}

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1.5rem !important;
}

    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-nav .layout-dropdown-menu {
        position: static;
        float: none;
    }

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

    .navbar-toggler:hover, .navbar-toggler:focus {
        text-decoration: none;
    }

    .navbar-toggler:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

        .navbar-expand-sm .navbar-nav {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }

            .navbar-expand-sm .navbar-nav .layout-dropdown-menu {
                position: absolute;
            }

            .navbar-expand-sm .navbar-nav .nav-link {
                padding-right: 0.5rem;
                padding-left: 0.5rem;
            }

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }

        .navbar-expand-sm .navbar-toggler {
            display: none;
        }

    .modal-dialog {
        max-width: 600px;
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

        .navbar-expand-md .navbar-nav {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }

            .navbar-expand-md .navbar-nav .layout-dropdown-menu {
                position: absolute;
            }

            .navbar-expand-md .navbar-nav .nav-link {
                padding-right: 0.5rem;
                padding-left: 0.5rem;
            }

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }

        .navbar-expand-md .navbar-toggler {
            display: none;
        }

    .modal-dialog {
        max-width: 900px;
    }

    .rolechange-notification-modal .modal-dialog {
        max-width: 580px;
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

        .navbar-expand-lg .navbar-nav {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }

            .navbar-expand-lg .navbar-nav .layout-dropdown-menu {
                position: absolute;
            }

            .navbar-expand-lg .navbar-nav .nav-link {
                padding-right: 0.5rem;
                padding-left: 0.5rem;
            }

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }

        .navbar-expand-lg .navbar-toggler {
            display: none;
        }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

        .navbar-expand-xl .navbar-nav {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }

            .navbar-expand-xl .navbar-nav .layout-dropdown-menu {
                position: absolute;
            }

            .navbar-expand-xl .navbar-nav .nav-link {
                padding-right: 0.5rem;
                padding-left: 0.5rem;
            }

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }

        .navbar-expand-xl .navbar-toggler {
            display: none;
        }
}

.navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-expand .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

        .navbar-expand .navbar-nav .layout-dropdown-menu {
            position: absolute;
        }

        .navbar-expand .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-expand .navbar-toggler {
        display: none;
    }

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

    .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
        color: rgba(0, 0, 0, 0.9);
    }

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

    .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
        color: rgba(0, 0, 0, 0.7);
    }

    .navbar-light .navbar-nav .nav-link.disabled {
        color: rgba(0, 0, 0, 0.3);
    }

    .navbar-light .navbar-nav .show > .nav-link,
    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .nav-link.active {
        color: rgba(0, 0, 0, 0.9);
    }

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

    .navbar-light .navbar-text a {
        color: rgba(0, 0, 0, 0.9);
    }

        .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
            color: rgba(0, 0, 0, 0.9);
        }

.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 1.75rem 2rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: white;
    border-radius: 0px !important;
    border-bottom: 1px solid #e8e8e8;
    height: 50px;
    font-size: 1.25rem;
    position: fixed;
    width: 100%;
    z-index: 10;
    box-shadow: 0 0 8px #dcdcdc;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
}

    .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #6c757d;
        content: "/";
    }

    .breadcrumb-item + .breadcrumb-item:hover::before {
        text-decoration: underline;
    }

    .breadcrumb-item + .breadcrumb-item:hover::before {
        text-decoration: none;
    }

.breadcrumb-item.active {
    color: #6c757d;
}

.breadcrumb-item a {
    color: teal;
}

#wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

    #wrapper #content-wrapper {
        overflow-x: hidden;
        width: 100%;
        overflow: auto;
    }


.navbar-nav .form-inline .input-group {
    width: 100%;
}

.navbar-nav .nav-item.active .nav-link {
    color: #fff;
}

.navbar-nav .nav-item.layout-dropdown .layout-dropdown-toggle::after {
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f105';
    font-family: 'Font Awesome 5 Free';
    color: black;
}

.navbar-nav .nav-item.layout-dropdown .layout-dropdown-toggle span {
    margin: 10px;
}

.navbar-nav .nav-item.layout-dropdown.show .layout-dropdown-toggle::after {
    content: '\f107';
}

.navbar-nav .nav-item.layout-dropdown.no-arrow .layout-dropdown-toggle::after {
    display: none;
}

.navbar-nav .nav-item .nav-link:focus {
    outline: none;
}

.navbar-nav .nav-item .nav-link .badge {
    position: absolute;
    margin-left: 0.75rem;
    top: 0.3rem;
    font-weight: 400;
    font-size: 0.5rem;
}

@media (min-width: 768px) {
    .navbar-nav .form-inline .input-group {
        width: auto;
    }
}

footer.sticky-footer {
    position: absolute;
    right: 0;
    bottom: 0px;
    height: 20px;
    background-color: rgb(241, 236, 236);
    width: 100%;
    font-size: 11px;
    padding: 0px 7px 0px 7px;
}

    footer.sticky-footer .copyright {
        line-height: 1;
        font-size: 0.8rem;
    }

body.sidebar-toggled footer.sticky-footer {
    width: 100%;
}

.nav-item > .nav-link:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: white;
}

.nav-item button {
    padding-right: 10px !important;
    font-size: 14px;
    line-height: .5;
}

.layout-dropdown-menu .layout-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.50rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 14px;
}

    .layout-dropdown-menu .layout-dropdown-item:hover {
        background-color: rgba(0, 0, 0, 0.05);
        text-decoration: none;
    }

.form-control, .react-bs-table-bordered, .btn, .Select-control {
    border-radius: 0px !important;
}

    .btn i, .btn-xs, .btn-group-xs > .btn {
        border-radius: 0px !important;
    }

.pagination > li:first-child > a, .pagination > li:first-child > span,
.pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: 0px !important;
}

.table-hover > tbody > tr:hover table-hover > tbody > tr {
    background-color: #edf9ff;
}

.table-hover > tbody > tr:hover {
    background-color: #edf9ff;
    cursor: pointer
}

.row-selected {
    background-color: #caedff !important;
}

label, table {
    font-size: 12px;
}

.nav-tabs.nav-justified > li > a {
    border-radius: 0px !important;
}

.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
    border-top: 4px solid #0af;
}

.bootstrap-grid-no-padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.bootstrap-grid-sm-padding {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.bootstrap-grid-md-padding {
    padding-right: 10px !important;
    padding-left: 10px !important;
}

.tag-label {
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-right: 5px;
    font-size: smaller;
}

.tab-content-container {
    font-size: 14px;
    background-color: #ffffff;
}


.tab-content-container-middle-panel {
    padding: 15px 15px 0px 15px;
    background-color: grey;
    text-align: center;
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
}

    .tab-content-container-middle-panel .pr-center-pannel .btn-toolbar .dropdown .dropdown-toggle::after {
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }


.tab-content-container-middle-panel-no-content {
    padding: 15%;
    text-align: center;
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
}


.tab-content-container-right-panel {
}

.tab-no-content-message {
    color: white;
}


/* ===== Begin Procee Return Modal ===== */
.contentClassName {
    top: 0px !important;
}
/* Need to remove the header*/

.contentClassName {
    top: 0px !important;
}
/* Need to remove the header*/
.custom-window {
    position: relative;
    height: 100%;
}

    .custom-window .custom-window-header {
        background: rgb(241, 236, 236);
        padding: 10px 15px;
        height: 50px;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        line-height: 30px;
    }

        .custom-window .custom-window-header .header-inner {
            position: relative;
            padding-right: 130px;
            padding-left: 35px;
        }

            .custom-window .custom-window-header .header-inner .fa-rocket {
                color: gray;
                font-size: 22px;
                position: absolute;
                left: 0px;
                top: 4px;
            }

            .custom-window .custom-window-header .header-inner .title {
                color: #1a8fbf;
                font-size: 16px;
                font-weight: bold;
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
            }

            .custom-window .custom-window-header .header-inner .header-btn {
                position: absolute;
                top: 0px;
                right: 0px;
            }

                .custom-window .custom-window-header .header-inner .header-btn button {
                    width: 24px;
                    height: 24px;
                    font-size: 14px;
                    margin-left: 2px;
                    background: transparent;
                    border: 1px solid transparent;
                    color: #000000;
                    opacity: .4;
                    line-height: 24px;
                }

                    .custom-window .custom-window-header .header-inner .header-btn button:hover {
                        border: 1px solid #C1C1C1;
                        opacity: .6;
                    }

    .custom-window .custom-window-content {
        padding: 65px 0px 85px;
        height: 100%;
    }
        .custom-window .custom-window-content .nav-tabs {
            margin-bottom: 10px;
            font-size: 14px;
        }
    .custom-window .tab-content {
        height: calc(100% - 40px);
        overflow-x: hidden;
    }

        .custom-window .tab-content .tab-pane, .custom-window .tab-content .tab-pane .h100 {
            height: 100%;
        }

    .custom-window .custom-window-content .pr-right-pannel, .custom-window .custom-window-content .pr-left-pannel, .custom-window .custom-window-content .pr-center-pannel {
        position: relative;
        height: 100%;
    }

    .custom-window .custom-window-content .scroll {
        overflow-y: auto;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
    }

    .custom-window .custom-window-content .box {
        height: 320px;
        display: block;
        background: #CCC;
        margin-bottom: 10px;
    }

    .custom-window .custom-window-footer {
        height: 70px;
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 15px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: white;
    }

        .custom-window .custom-window-footer .btn + .btn {
            margin-left: 10px;
        }

#process-return-groups {
    height: 100%;
    position: relative;
}

.input-group.date > input[type=text] {
    z-index: 0 !important;
}

.multi-select-widthauto .Select-menu-outer {
    z-index: 999 !important;
    width: auto !important;
    font-size: 11px !important;
    padding: 6px 6px 4px 2px;
}

.multi-select-width100 .Select-menu-outer {
    width: 100% !important;
    font-size: 11px !important;
    padding-top: 5px;
}

.multi-select-control .Select-menu-outer {
    width: 100%;
    font-size: 11px;
    padding-top: .3rem;
}

.bg-transparent {
    background-color: transparent !important;
}

.svg-shadow {
    filter: drop-shadow(10px 10px 5px #DADADA);
}

.sort-column[title='Status'],
.sort-column[title='Tax Year'],
.sort-column[data-field='documentStatus'],
.sort-column[data-field='lastReminder'],
.sort-column[data-field='signingReminder'],
.sort-column[data-field='organizerReminder'],
.sort-column[data-field='downloadedDate'],
th.tax-year,
th.signature-status,
th.organizer-status,
th.source-document-status {
    overflow: inherit !important;
}

.react-bs-container-header {
    overflow: inherit !important;
}

.Select-multi-value-wrapper span:not(:first-child) {
    display: none;
}

.textalign-right {
    text-align: right;
}

.upload-doc-modal .prosystem-upload-doc-body {
    overflow: auto;
}

.batch-note-info {
    display: flex;
    margin-bottom: 10px;
    align-items: baseline;
    gap: 5px;
}

/* SF */
.sf-container {
    width: 100%;
    margin-top: 8px;
}

.sf-progressbar {
    counter-reset: step;
}

    .sf-progressbar li {
        list-style: none;
        display: inline-block;
        width: 25%;
        position: relative;
        text-align: center;
        cursor: pointer;
        vertical-align: top;
    }

        .sf-progressbar li:before {
            content: counter(step);
            counter-increment: step;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #ddd;
            border-radius: 100%;
            display: block;
            text-align: center;
            margin: 0 auto 10px auto;
            background-color: #fff;
        }

        .sf-progressbar li:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #ddd;
            top: 15px;
            left: -50%;
            z-index: -1;
        }

        .sf-progressbar li:first-child:after {
            content: none;
        }

        .sf-progressbar li.sfstep-active:before {
            border-color: #1d9bff;
            background-color: #1d9bff;
            color: white;
        }


        .sf-progressbar li.sfstep-completed {
            color: rgb(0, 128, 0);
        }

            .sf-progressbar li.sfstep-completed:before {
                border-color: rgb(0, 128, 0);
                content: '\2713';
                background-color: rgb(0, 128, 0);
                color: white;
            }

            .sf-progressbar li.sfstep-completed + li:after {
                background-color: rgb(0, 128, 0);
            }

.panel-footer .btn + .btn {
    margin-left: 10px;
}

.sf-panel-header {
    background-color: white;
    position: sticky;
    top: 0px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: white;
    /* font-size: 3rem; */
    /* position: relative; */
    z-index: 10;
    height: 100px;
}

.sf-panel-footer {
    position: absolute;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: right;
    -ms-flex-align: center;
    align-items: right;
    -ms-flex-pack: center;
    justify-content: flex-end;
    background-color: white;
    background-color: white;
    text-align: right;
    margin: -1px;
}

.text-center-align {
    text-align: center !important;
}

.text-left-align {
    text-align: left !important;
}

#sfUploadDocumentTable .react-bs-table-container .react-bs-table .react-bs-container-body {
    overflow: inherit;
}

.sfPanel-body {
    position: relative;
    overflow-x: hidden;
    overflow: auto;
    height: calc(100vh - 230px);
}

.custom-sfPanel-body {
    position: relative;
    overflow-x: hidden;
    overflow: hidden;
    height: calc(100vh - 230px);
}


    .custom-sfPanel-body .tab-content-container {
        height: 100%;
    }

    .custom-sfPanel-body .tab-content-container-left-panel {
        height: 100%;
    }

    .custom-sfPanel-body .tab-content-container-middle-panel {
        height: 100%;
    }

    .custom-sfPanel-body .tab-content-container-right-panel {
        height: 100%;
    }

    .custom-sfPanel-body .tab-content-container .pr-right-pannel,
    .custom-sfPanel-body .tab-content-container .pr-left-pannel,
    .custom-sfPanel-body .tab-content-container .pr-center-pannel {
        position: relative;
        height: 100%;
    }

    .custom-sfPanel-body .scroll {
        overflow-y: auto;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
    }

    .custom-sfPanel-body .box {
        height: 320px;
        display: block;
        background: #CCC;
        margin-bottom: 10px;
    }

.accordian-panel .panel .panel-heading .panel-title .accordion-toggle:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings'; /* essential for enabling glyphicon */
    content: "\e114"; /* adjust as needed, taken from bootstrap.css */
    float: right; /* adjust as needed */
    color: grey; /* adjust as needed */
    font-style: normal;
}

.accordian-panel .panel .panel-heading .panel-title .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    font-family: 'Glyphicons Halflings'; /* essential for enabling glyphicon */
    content: "\e080"; /* adjust as needed, taken from bootstrap.css */
    font-style: normal;
}

.sf-receipient-signer-sign-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(242,227,12,1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(242,227,12,1);
    box-shadow: 0px 0px 10px 5px rgba(242,227,12,1)
}

.sf-sender-signer-sign-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(247,241,126,1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(247,241,126,1);
    box-shadow: 0px 0px 10px 5px rgba(247,241,126,1);
}

.mar-B0 {
    margin-bottom: 0px !important;
}

.bookmarksPane .accordian-panel .panel {
    border-radius: 0px !important;
    margin-bottom: 0px !important;
}

#mailMergeUploadCsvTable {
    padding-top: 7%;
}

    #mailMergeUploadCsvTable .react-bs-table-container .react-bs-table .react-bs-container-body {
        overflow: inherit;
    }

    #mailMergeUploadCsvTable .react-bs-container-header.table-header-wrapper {
        display: none;
    }

.invalidMailMergeCellValue {
    color: red;
    cursor: pointer;
}

#div-validation-btn {
    height: 29vh;
    background-color: lightgrey;
    text-align: center;
    padding-top: 8vh;
}

    #div-validation-btn div {
        margin-top: 10px;
    }

.mailmerge-custom-field {
    border: none;
}

.mailmerge-custom-field-tag {
    background-color: #66afe930;
    border-radius: 5px;
    border: 1px solid #3791d8;
    color: black;
    display: inline-block;
    font-family: sans-serif;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2px;
    cursor: pointer;
}

.mailmerge-custom-field-tag-remove {
    color: #274c96;
    padding-left: 5px;
    cursor: pointer;
}

.custom-field-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(209,196,8,1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(209,196,8,1);
    box-shadow: 0px 0px 10px 5px rgba(209,196,8,1);
}

#pdfpreview .modal-content {
    display: inline-block;
}

.csv-table-td-error {
    outline-color: red;
    outline-style: solid;
    outline-width: 3px;
    outline-offset: -1px;
}

.react-bs-table .table-bordered > tbody > tr > td.csv-table-td-error {
    outline: 3px solid red !important;
    outline-offset: -1px;
}

.react-bs-table .table-bordered > tbody > tr > td.csv-table-td-warning {
    outline: 3px solid orange !important;
    outline-offset: -1px;
}

/* .react-bs-table .react-bs-container-header > table > thead > tr > th {
    color: green;
    text-overflow: ellipsis !important;
} */


.margin-tbl-20 {
    /*margin: 20px 0 20px 20px;*/
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-7 {
    margin-top: 7px;
}

.margin-bottom-5 {
    margin-bottom: 5px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-left-10 {
    margin-left: 10px;
}

.beforeContent {
    position: relative;
}

    .beforeContent::before {
        content: '-';
        position: absolute;
        left: -10px;
    }

@media screen and (min-width: 1360px) {
    #ddlUploadSFDocument .Select-arrow-zone {
        width: 15px;
    }
}

.organizer-sender-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(242,227,12,1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(242,227,12,1);
    box-shadow: 0px 0px 10px 5px rgba(242,227,12,1)
}

.organizer-taxpayer-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(247,241,126,1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(247,241,126,1);
    box-shadow: 0px 0px 10px 5px rgba(247,241,126,1);
}

.organizer-spouse-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(209,196,8,1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(209,196,8,1);
    box-shadow: 0px 0px 10px 5px rgba(209,196,8,1);
}

.react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu {
    position: fixed !IMPORTANT;
}

    .react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu li {
        padding-top: 2px;
    }

        .react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu li:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        .react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu li a {
            color: black;
            font-size: 14px;
            padding-right: 5px;
            text-decoration: none;
        }

.filters ul li:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.app-header-menu-item ul li a {
    text-decoration: none;
}

.app-header-menu-item ul li:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.page-item.active .page-link {
    background-color: #337ab7;
    border-color: #337ab7;
}

a {
    color: #337ab7;
}

.pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}

.nav-tabs .nav-link {
    color: #337ab7 !important;
    padding: 10px
}

    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: #495057 !important;
        border-top: 4px solid #0af;
        padding: 10px
    }

.edit-client-instruction-modal {
    font-size: 14px;
}

    .last-login .modal-content,
    .my-account-modal .modal-content,
    .change-password-modal .modal-content,
    .logout-confirm-modal .modal-content,
    .resend-access-link-modal .modal-content,
    .reopen-organizer-modal .modal-content,
    .report-problem-modal .modal-content,
    .session-timeout-modal .modal-content,
    .columnsettings-modal .modal-content {
        width: 600px !important;
    }
.change-password-modal .alert{
    margin: 0;
    margin-bottom: 14px;
}
.edit-client-info-modal .modal-content,
.edit-batch-info-modal .modal-content,
.download-organizer-modal .modal-content {
    width: 700px !important;
}

.report-problem-modal .modal-content {
    width: 800px !important;
    font-size: 14px;
}

    .report-problem-modal .modal-content .modal-body {
        padding-left: 15px;
        padding-right: 15px;
    }

        .report-problem-modal .modal-content .modal-body .Select-value-label {
            font-size: 13px;
        }


        .report-problem-modal .modal-content .modal-body .Select.is-disabled > .Select-control {
            background-color: #e9ecef !important;
        }

.report-problem-modal .form-group {
    width: 100%;
}

.session-timeout-modal .modal-body {
    font-size: 14px;
}

.custom-window .bookmarksPane .card-header h3 {
    font-size: 16px;
    font-weight: 400;
    background-color: inherit;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
}

.card-header {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}

.bookmarksPane .card .card-title, .accordion .card .card-header, .accordion .card .card-header .card-title {
    font-size: 16px;
    text-align: left;
    margin-bottom: 0;
    margin: 0;
}

.bookmarksPane .accordion .card .card-header {
    display: flex;
    justify-content: space-between;
}

    .bookmarksPane .accordion .card .card-header .float-right {
        display: inline-block;
        margin-top: -3px;
    }

.modal-content {
    font-size: 14px;
}

.toaster_message {
    width: 405px;
    padding: 10px !important;
    white-space: pre-line;
}

/* Popup modal Icons -Bug fix */

.modal-header {
    align-items: baseline;
}

/* My account modal UI bug fix Spacing issue*/
.my-account-icon {
    margin-left: 0;
    padding-left: 0
}

.source-documents {
    display: flex;
    width: 100%;
    padding-bottom: 5px
}

    .source-documents > h3 {
        width: 29%;
    }

.custom-download-type {
    width: 440px !important;
}

    .custom-download-type > .Select-control {
        font-size: 13px !important;
    }

.source-documents .Select {
    width: 70%;
}

    .source-documents .Select .Select-arrow-zone:hover > .Select-arrow {
        border-top-color: #fff;
    }

.source-documents .Select-control > *:last-child {
    padding-right: 0px;
}

.source-documents .Select-arrow-zone {
    background-color: #1a8fbf;
}

.source-documents .Select-arrow {
    border-color: #fff transparent transparent;
}

.source-documents .Select.is-open > .Select-control .Select-arrow {
    border-color: transparent transparent #fff;
}

.source-documents .Select-control {
    font-size: 11px;
    width: 32%;
    height: 28px;
    border: none;
    margin-top: 2px;
}

.source-documents .Select .Select-menu-outer {
    width: 32% !important;
    font-size: 11px;
    background-color: #ff0;
}

.source-documents .Select-input {
    height: 22px;
}

.source-documents .Select-placeholder {
    top: -4px;
    background-color: #21a9e1;
    color: #fff;
    cursor: pointer;
}

    .source-documents .Select-placeholder:hover {
        background-color: #1a8fbf;
    }

.source-documents .Select-option {
    padding: 4px 10px;
}

.batch-in-process-info-modal-heading {
    padding-top: 8px;
    padding-left: 0px;
}

    .batch-in-process-info-modal-heading h4 {
        color: #21a9e1;
    }

.reopen-sourcedocumentupload-modal .modal-content {
    width: 700px !important;
}

    .reopen-sourcedocumentupload-modal .modal-content .modal-header {
        align-items: center;
    }

.modal-icon {
    color: grey;
    margin-right: 5px;
    vertical-align: text-bottom;
    font-size: 20px;
}

.batch-delivery-inprogress-modal .modal-content {
    width: 700px !important;
}

    .batch-delivery-inprogress-modal .modal-content .modal-header {
        align-items: center;
    }

.preparer-message-info-div {
    margin-top: 2%;
}

    .preparer-message-info-div span {
        margin-left: 5px;
    }

    .preparer-message-info-div i {
        color: #21a9e1;
    }

.preparer-message-font-size {
    font-size: 14px;
}

.preparer-message-list-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px dotted #ddd;
    white-space: nowrap;
}

    .preparer-message-list-item.custom-active, .preparer-message-list-item.custom-active:hover, .preparer-message-list-item.custom-active:focus {
        color: #0c5460;
        background-color: #d1ecf1;
        border-color: #b8daff;
    }

.default-span {
    background-color: #777 !important;
    border-radius: 10px !important;
    color: #fff !important;
    margin-left: 10px;
    float: initial;
}

.form-pre-mes-body {
    height: 14.0rem;
}

.pre-mes-body {
    overflow-y: auto;
    background-Color: #f1f1f1;
}

.height-85p {
    height: 85%;
}

.height-101p {
    height: 101%;
}

.prepare-allow-message {
    margin-bottom: 0rem !important;
    padding-bottom: 0rem !important;
    line-height: 0rem !important;
}

.delete-modal .modal-content {
    width: 700px !important;
}

.calendarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%
}

.calendarInput {
    position: absolute;
    z-index: 2000;
    top: 30px;
}

.calendarClass {
    min-width: 0px;
    display: inline-block;
    position: relative;
    width: 100%
}

.inputClass {
    width: 100%;
    height: 36px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    font-size: 12px;
    padding-left: 6px
}

.form-control {
    border-radius: 0px !important;
}

.minusClass {
    position: absolute;
    top: 0%;
    right: 0%;
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
    border-radius: 0px 3px 3px 0px;
    height: 100%;
    font-family: monospace
}

.group-input {
    height: 36px;
}

.minusClass:hover {
    cursor: pointer;
}

#dateId .dateClass .react-datepicker-container .react-datepicker-top {
    background: #ddd;
    color: black;
    border-color: #ddd;
}

#datepicker_input div {
    min-width: 0px;
}

    #datepicker_input div .react-datepicker {
        top: 100%;
    }

.react-datepicker {
    font-size: 1.3rem !important;
}

.date-picker-column {
    overflow: auto !important;
}

.sort-column[title='Date'] {
    overflow: visible !important;
}

.header-div {
    overflow: hidden;
    width: calc(100% - 19px);
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.header-caret-up {
    margin: 10px 0px 10px 5px;
    color: rgb(204, 204, 204);
}

.header-caret-down {
    margin: 10px 0px;
    color: rgb(204, 204, 204);
}

.navbar-nav .nav-item.layout-dropdown.show .layout-dropdown-toggle.sub-menu-item-nav-show::after {
    content: '\f107';
    font-size: initial;
}

.navbar-nav .nav-item.layout-dropdown.show .layout-dropdown-toggle.sub-menu-item-nav-hide::after {
    content: '\f105';
    font-size: initial;
}

.relaod-span {
    padding: '2px 0';
    text-shadow: '0px 1px 0px rgba(255,255,255,.5)';
    font-size: '12px';
}

.download-organizer-modal .modal-content .modal-header {
    align-items: center;
}

.recycle-report-refresh-btn {
    height: 38px;
    padding-left: 12px;
}

.react-bs-table .table-bordered > thead > tr:first-child > th {
    overflow: visible !important;
}

.organizer-sign-lable {
    margin-top: 8px;
    padding-right: 0px;
    font-size: 13px;
    margin-left: 30px;
}

.pg-viewer .react-grid-HeaderCell-sortable {
    pointer-events: none;
}

.preview-modal-header-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.layout-wrapper main {
    overflow: hidden !important;
}

.font14 {
    font-size: 14px !important;
}


/* Loading Screen */

    .flex-column-center-all {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.login-form-container {
    text-align: center;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: auto;
}

.inner-logo-container {
    width: 235px;
    -moz-transition: all 550ms ease-in-out;
    -o-transition: all 550ms ease-in-out;
    -webkit-transition: all 550ms ease-in-out;
    transition: all 550ms ease-in-out;
}

.loading-text {
    margin-top: 50px;
}

.login-container {
    width: 100vw;
    height: 100vh;
    background-color: #F0F1F1;
    -moz-transition: all 550ms ease-in-out;
    -o-transition: all 550ms ease-in-out;
    -webkit-transition: all 550ms ease-in-out;
    transition: all 550ms ease-in-out;
}

.flex-column-center-all {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.user-autologout-modal-title {
    color: rgb(32,37,41);
    margin: 0px;
    font-weight: 600;
    font-size: 25px;
    padding: 7px 0px 10px 7px;
    display: inline-block;
}

.user-autologout-modal-content {
    margin-left: 7px;
    font-size: 19px;
    padding-bottom: 7px;
}

.user-autologout-modal-footer-btn {
    padding: 11px 26px !important;
    font-size: 17px !important;
    box-shadow: none;
    border-radius: 5px !important;
}

.reopen-organizer {
    padding: 0px 10px;
    box-sizing: border-box;
}

.img-reopen-organizer-icon {
    margin-right: 10px;
    width: 15.75px;
    height: 14px;
}

.support-icon {
    width: 21px;
    height: 20px;
    cursor:pointer;
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#divLoading::after {
    content: '';
    display: block;
    position: absolute;
    left: 49%;
    top: 45%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: black;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

#divLoading {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /*background-color: rgba(255,255,255,0.7);*/
    z-index: 9999;
    display: none;
}

.loader-text {
    text-align: center;
    font-weight: 700;
    font-size: 100%;
    margin-top: 25%;
}
.walkme-custom-icon-outer-div{
    z-index: 99 !important;
}

.dot-spin-loader {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 0 #88c656,
        12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0),
        -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0),
        -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
    animation: dot-spin-loader 1.5s infinite linear;
}

.taxpayer-name-formatter {
    display: flex;
    align-items: center;
}
.taxpayer-name-formatter .ellipsis {
    width: auto;
}
.taxpayer-name-formatter .linked-details-overLay-button {
    border: none;
    background: none;
    display: flex;
    margin-left: 5px;
}
.linked-details-popover {
    margin-bottom: -5px !important;
    box-shadow: none;
    border-radius: 4.8px;
    border: 1px solid #000 33 !important;
}
.linked-details-popover .details-container {
    padding: 16px;
    max-width: 300px;
    max-height: 298px;
    overflow-y: auto;
    overflow-x: hidden;
}
.linked-details-popover .details-container .connected-with-text {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
}
.linked-details-popover .details-container .linked-data {
    padding-top: 0rem;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    color: #212529;
}
.linked-details-popover .details-container .linked-data .linked-clientName {
    font-weight: 700;
    margin-top: 1rem;
    color: #212529;
}
.linked-details-popover .details-container .linked-data .linked-clientId {
    font-weight: 400;
    color: #565a5e;
}
.linked-details-popover .details-container .linked-data .linked-emailId {
    font-weight: 400;
    color: #212529;
}
.linked-details-popover .details-container .linked-data .primary-emailId {
    font-weight: 600;
    color: #0973ba;
}

.linked-details-popover .bs-popover-auto[x-placement^=top], .bs-popover-top {
    margin-bottom: -3px !important;
}
.linked-details-popover .bs-popover-auto[x-placement^=top] .popover-arrow::before, .bs-popover-top .popover-arrow::before {
    border-width: 9px 9px 9px 9px !important;
    left: -1px;
    top: 0px;
}
.linked-details-popover .bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
    margin-top: -3px !important;
}
.linked-details-popover .bs-popover-auto[x-placement^=bottom] .popover-arrow::before, .bs-popover-bottom .popover-arrow::before {
    border-width: 0px 9px 9px 9px !important;
    left: -1px;
}


@keyframes dot-spin-loader {
    0%,
    100% {
        box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 0 #88c656,
            12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    12.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 0 #88c656,
            12.727926px 12.727926px 0 0 #88c656, 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    25% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 0 #88c656, 12.727926px 12.727926px 0 0 #88c656, 0 18px 0 0 #88c656,
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    37.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 0 #88c656, 0 18px 0 0 #88c656,
            -12.727926px 12.727926px 0 0 #88c656, -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    50% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #88c656,
            -12.727926px 12.727926px 0 0 #88c656, -18px 0 0 0 #88c656, -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    62.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 #88c656, -18px 0 0 0 #88c656,
            -12.727926px -12.727926px 0 0 #88c656;
    }
    75% {
        box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #88c656,
            -12.727926px -12.727926px 0 0 #88c656;
    }
    87.5% {
        box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 -5px rgba(152, 128, 255, 0),
            12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 0 #88c656;
    }
}
.backgroudBlue {
    background-color: #E6F1F8 !important;
}
    .backgroudBlue .Select-control {
        background-color: #E6F1F8 !important;
    }
.inputinput-mask i{
    right: 10px;
}

.inputinput-mask input::-ms-reveal,
input::-ms-clear{
    display: none !important;
}

.organizer-info-card.card{
    overflow: visible !important;
}

.select-value-fontSize {
    margin-bottom: 10px;
    font-size: 14px;
}
#react-doc-viewer #proxy-renderer{
    overflow: hidden;
}
#react-doc-viewer #image-renderer{
    background-image:none ;
}